import * as React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints";
import footerBackground from "../../resources/img/bamboo-panels/footer-background-desktop.png";
import footerBackgroundMobile from "../../resources/img/bamboo-panels/footer-background-mobile.png";
import SocialLinks from "../Core/SocialLinks";
import logo from "../../resources/img/logo.svg";
import {Link} from "gatsby";
import colors from "../../styles/colors";

const Logo = styled.img`
  width: 115px;
`;

const FooterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  text-align: center;
  flex-direction: column;
  padding: 32px 24px;
  margin: auto;
  max-width: 280px;

  @media (min-width: ${breakpoints.xl}) {
    padding: 32px 24px;
    max-width: 990px;
  }
`;

const FooterNav = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  flex-direction: column;

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;
    font-size: 15px;
  }
`;

const CopyrightBlock = styled.div`
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: flex-start;
  align-items: center;
`;

const FooterLinks = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 120px;
  
  a {
    margin-right: 20px;
  }

  @media (min-width: ${breakpoints.xl}) {
    margin-bottom: 12px;
  }
`;

const Copyright = styled.div`
  margin-right: 32px;
`;

const StyledLinked = styled(Link)`
  text-decoration: underline;
  color: ${colors.white};
`;

const FooterContainer = styled.div`
  width: 100%;
  max-width: 377px;
  height: 440px;
  margin: auto;
  background: url(${footerBackgroundMobile}) no-repeat #1697C1;
  background-size: 100% auto;
  padding-top: 48px;

  @media (min-width: ${breakpoints.xl}) {
    max-width: 1280px;
    height: 303px;
    padding-top: 104px;
    background: url(${footerBackground}) no-repeat #1697C1;
    background-size: 100% auto;
  }
`;

const PreFooter = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 40px;

  @media (min-width: ${breakpoints.xl}) {
    margin-bottom: 0;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <PreFooter>
          <Link to={"/"}>
            <Logo src={logo} alt={"Panda Dynasty"}/>
          </Link>
        </PreFooter>
        <FooterNav>
          <CopyrightBlock>
            <Copyright>
              © {new Date().getFullYear()} Panda Dynasty
            </Copyright>
          </CopyrightBlock>
          <FooterLinks>
            <StyledLinked to={"/about"}>
              About
            </StyledLinked>
            <StyledLinked to={"/terms"}>
              Terms of use
            </StyledLinked>
            <StyledLinked to={"/game-rules"}>
              Game rules
            </StyledLinked>
          </FooterLinks>
          <SocialLinks/>
        </FooterNav>
      </FooterContent>
    </FooterContainer>
  )
}

export default Footer;

