import {createGlobalStyle} from "styled-components"
import reset from "styled-reset"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import gloryRegularWoff from "../../resources/fonts/glory-regular.woff";
import gloryRegularWoff2 from "../../resources/fonts/glory-regular.woff2";
import gloryBoldWoff from "../../resources/fonts/glory-bold.woff";
import gloryBoldWoff2 from "../../resources/fonts/glory-bold.woff2";
import gloryExtraBoldWoff from "../../resources/fonts/glory-extrabold.woff";
import gloryExtraBoldWoff2 from "../../resources/fonts/glory-extrabold.woff2";

const fontFace = (fontFamily, woff, woff2) => {
  return `
      @font-face {
          font-family: '${fontFamily}';
          src: url(${woff2}) format('woff2'),
          url(${woff}) format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: auto;
      }
    `
};

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  ${fontFace("Glory Regular", gloryRegularWoff, gloryRegularWoff2)};
  ${fontFace("Glory Bold", gloryBoldWoff, gloryBoldWoff2)}  ;
  ${fontFace("Glory Extra Bold", gloryExtraBoldWoff, gloryExtraBoldWoff2)};

  body {
    ${fonts.gloryRegular};
    background: #1697C1;
    color: ${colors.white};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  a {
    transition: all 0.2s;
  }
  
  ::selection {
    background: ${colors.white};
    color: ${colors.darkGreen};
  }

  ::-moz-selection {
    background: ${colors.white};
    color: ${colors.darkGreen};
  }
`;

export default GlobalStyle;
