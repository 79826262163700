import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import GlobalStyle from "./mapstyle"
import {BaseCSS} from "styled-bootstrap-grid"
import {GridThemeProvider} from "styled-bootstrap-grid"
import {ThemeProvider} from "styled-components"
import Footer from "./Footer";

const PageContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  background: #1697C1;
`;

const GlobalPageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding: 0;
  width: 100%;
  overflow: hidden;
  margin: auto;
`;

const gridTheme = {
  breakpoints: {
    xl: 1280,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 0,
  },
  col: {
    padding: 0,
  },
  container: {
    padding: 0,
    maxWidth: {
      xl: 1600,
      lg: 1600,
      md: 1600,
      sm: 1600,
      xs: 1600,
    },
  },
};

const styledTheme = {};

const Layout = ({children, footer}) => (
  <React.Fragment>
    <ThemeProvider theme={styledTheme}>
      <GridThemeProvider gridTheme={gridTheme}>
        <GlobalStyle/>
        <BaseCSS/>
        <GlobalPageContainer>
          <PageContainer>
            {children}
          </PageContainer>
          <Footer />
        </GlobalPageContainer>
      </GridThemeProvider>
    </ThemeProvider>
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
