import * as React from "react"
import styled from "styled-components"
import logoHome from "../../resources/img/logo/logo-small.svg";
import {Link} from "gatsby";
import breakpoints from "../../styles/breakpoints";

const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -57px;
  z-index: 100;
  top: 74px;

  @media (min-width: ${breakpoints.lg}) {
    top: 32px;
  }
`;

const LogoImage = styled.img`
  height: 64px;
`;

const Logo = () => {
  return (
    <LogoContainer>
    <Link to={"/"}>
      <LogoImage src={logoHome} alt={"Panda Dynasty"}/>
    </Link>
    </LogoContainer>
  )
}

export default Logo;
