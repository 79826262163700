import React, {useEffect, useState} from "react"
import Layout from "../components/Quest/Layout"
import Seo from "../components/Core/Seo"
import styled from "styled-components"
import {StaticImage} from "gatsby-plugin-image";
import breakpoints from "../styles/breakpoints";
import InteractivePanel from "../components/Quest/InteractivePanel";
import Logo from "../components/Quest/Logo";
import {isBrowser} from "../services/browser";

const PageContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;

  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;

    > div {
      height: 100%;
    }
  }
`;

const DesktopImage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`;

const MobileImage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: block;

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const IndexPage = ({location}) => {
  const maxPass = 8;
  const [currentPanel, setCurrentPanel] = useState(false);

  const setOpened = (panelNumber) => {
    setCurrentPanel(panelNumber);
  }

  const handleResize = () => {
    console.log('resize');
    const windowWidth = window.innerWidth;
    if (windowWidth < 992 && currentPanel === false) {
      setCurrentPanel(1);
    } else if (windowWidth >= 992) {
      setCurrentPanel(false);
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      handleResize();

      window.addEventListener("resize", handleResize)
    }

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, []);

  const updateMobilePanel = () => {
    if (!isBrowser()) {
      return;
    }

    const windowWidth = window.innerWidth;

    if (windowWidth >= 992) {
      return;
    }

    let newPass = currentPanel + 1;

    if (newPass > maxPass) {
      newPass = 1;
    }

    setCurrentPanel(newPass);
  }

  return (
    <Layout>
      <Seo title={"Quest Map | Panda Dynasty"}
           description={"Deep within the Bamboo Forest, located in a far off corner of the digital world, an extraordinary treasure hunt awaits. Join today by purchasing at least one of our Panda Dynasty NFTs. And remember, X always marks the spot!"}
           path={location.pathname}/>
      <PageContent>
        <Logo />
        <ImageContainer>
          <DesktopImage>
            <StaticImage
              src={"../resources/img/quest/map.jpg"}
              alt={"Quest map"}
              objectFit={"contain"}
              onClick={() => setOpened(false)}
            />
          </DesktopImage>
          <MobileImage>
            <StaticImage
              src={"../resources/img/quest/map-mobile.jpg"}
              alt={"Quest map"}
              objectFit={"cover"}
              onClick={() => updateMobilePanel()}
            />
          </MobileImage>
          <InteractivePanel top={65}
                            left={60}
                            passNumber={1}
                            onClick={setOpened}
                            currentPanelOpened={currentPanel}
                            passTitle={`Panda Dynasty NFT`}>
            <a href={"https://opensea.io/collection/pandadynasty"} target={"_blank"}>Grab your ticket</a> to proceed
            forward. By being a holder, the adventure begins.
          </InteractivePanel>
          <InteractivePanel top={68}
                            left={45}
                            passNumber={2}
                            onClick={setOpened}
                            currentPanelOpened={currentPanel}
                            passTitle={`Magical Pumpkin`}>
            Merge your panda NFT with matching <a href={"https://opensea.io/collection/panda-pumpkins"} target={"_blank"}>pumpkin</a> of your own. Only 8 colors will win.
          </InteractivePanel>
          <InteractivePanel top={54}
                            left={49}
                            passNumber={3}
                            onClick={setOpened}
                            currentPanelOpened={currentPanel}
                            passTitle={`Comics unlock`}>
            Thanks to <a href={"https://www.webtoons.com/en/challenge/panda-dynasty-age-of-the-dynasty/list?title_no=697468"} target={"_blank"}>clues</a> discovered in the comics you will <a href={"https://pandadynasty.io/pandapass"} target={"_blank"}>unlock a special NFT</a> that will help to open the treasure.
          </InteractivePanel>
          <InteractivePanel top={54}
                            left={33}
                            passNumber={4}
                            onClick={setOpened}
                            currentPanelOpened={currentPanel}
                            passTitle={`Lucky Bamboo Video Game Episode 1`}>
            Players that finish the first episode of the game will receive a Lucky Bamboo NFT. <a
            href={"https://pandadynasty.io/startgame"} target={"_blank"}>Start game</a>
          </InteractivePanel>
          <InteractivePanel top={50}
                            left={43.5}
                            passNumber={5}
                            onClick={setOpened}
                            currentPanelOpened={currentPanel}
                            comingsoon={"true"}
                            passTitle={`The Washing Machine`}>
            Looks like we are ready to clean our accessories. Enter the foundry for your accessory Panda Pass.
          </InteractivePanel>
          <InteractivePanel top={31}
                            left={40}
                            passNumber={6}
                            onClick={setOpened}
                            currentPanelOpened={currentPanel}
                            comingsoon={"true"}
                            passTitle={`Background Accessory`}>
            Extract the accessory from your Panda NFT to create a Panda Pass.
          </InteractivePanel>
          <InteractivePanel top={34}
                            left={58}
                            passNumber={7}
                            onClick={setOpened}
                            comingsoon={"true"}
                            currentPanelOpened={currentPanel}
                            passTitle={`Time Machine Video Game Episode 2`}>
            Be one of the first players to finish the second episode of the game and you’ll receive the time Machine NFT.
          </InteractivePanel>
          <InteractivePanel top={29}
                            left={48}
                            passNumber={8}
                            onClick={setOpened}
                            comingsoon={"true"}
                            currentPanelOpened={currentPanel}
                            passTitle={`Bamboo Ticket`}>
            Last NFT is the Bamboo Ticket that will give you a chance to win a part of the Bamboo treasure.
          </InteractivePanel>
        </ImageContainer>
      </PageContent>
    </Layout>
  )
};

export default IndexPage;
