import React, {useState} from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import BambooFrame from "../../resources/img/quest/bamboo-frame.png";
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import {isBrowser} from "../../services/browser";

const InteractivePanelContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -108px;
  margin-top: -98px;
  
  @media (min-width: ${breakpoints.lg}) {
    position: absolute;
    margin-left: 0;
    margin-top: 0;
    top: ${(props) => props.top && `${props.top}%`};
    left: ${(props) => props.left && `${props.left}%`};
  }
`;

const Pin = styled.div`
  position: relative;
  z-index: 20;
  display: none;
  cursor: pointer;

  @media (min-width: ${breakpoints.lg}) {
    display: ${(props) => props.show === "true" ? `block` : `none`};
  }
`;

const PinRing = styled.span`
  border: 3px solid #92988c;
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: 15px;
  top: 15px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.0;

  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1.0;
    }
    100% {
      -webkit-transform: scale(1.2, 1.2);
      opacity: 0.0;
    }
  }
`;

const PinCircle = styled.span`
  width: 20px;
  height: 20px;
  background: #92988c;
  border-radius: 50%;
  position: absolute;
  top: 17px;
  left: 17px;
`;

const Panel = styled.div`
  position: relative;
  z-index: 50;
  background: url(${BambooFrame}) no-repeat;
  background-size: cover;
  width: 216px;
  height: 197px;
  padding: 14px 26px 14px 26px;
  color: ${colors.black};
  text-align: center;
  display: ${(props) => props.show === "true" ? `flex` : `none`};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PassNumber = styled.div`
  ${fonts.gloryExtraBold};
  font-size: 24px;
  line-height: 30px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const PassTitle = styled.div`
  ${fonts.gloryBold};
  font-size: 18px;
  line-height: 26px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const PassText = styled.div`
  ${fonts.gloryRegular};
  font-size: 14px;
  line-height: 22px;

  a {
    color: ${colors.black};

    &:hover {
      color: ${colors.darkGreen};
    }
  }
`;

const InteractivePanel = ({top, left, passNumber, passTitle, children, currentPanelOpened, onClick}) => {
  const maxPass = 8;

  const updateMobilePanel = () => {
    if (!isBrowser()) {
      return;
    }

    const windowWidth = window.innerWidth;

    if (windowWidth >= 992) {
      return;
    }

    let newPass = passNumber + 1;

    if (newPass > maxPass) {
      newPass = 1;
    }

    onClick(newPass);
  }

  return (
    <InteractivePanelContainer top={top}
                               left={left}>
      <Pin show={currentPanelOpened === passNumber ? `false` : `true`}
           onClick={() => onClick(passNumber)}>
        <PinRing/>
        <PinCircle/>
      </Pin>
      <Panel show={currentPanelOpened === passNumber ? `true` : `false`}
             onClick={() => updateMobilePanel()}>
        <PassNumber>{passNumber}</PassNumber>
        <PassTitle>{passTitle}</PassTitle>
        <PassText>{children}</PassText>
      </Panel>
    </InteractivePanelContainer>
  );
}

export default InteractivePanel
